export default class {
  idx
  idy
  xdim
  ydim
  radiusX
  radiuY
  chancNew
  chancExtend
  chancVertical
  colors
  colorMode
  groupSize
  hSymmetric
  vSymmetric
  roundness
  solidness
  simple
  simplex
  rateOfChange
  globalSeed
  mainColor
  idCounter
  random

  constructor (
    width,
    height,
    {
      initiateChance = 0.8,
      extensionChance = 0.8,
      verticalChance = 0.8,
      horizontalSymmetry = true,
      verticalSymmetry = false,
      roundness = 0.1,
      solidness = 0.5,
      colors = [],
      colorMode = 'group',
      groupSize = 0.8,
      simple = false,
      simplex = null,
      rateOfChange = 0.01
    } = {},
    random
  ) {
    this.xdim = Math.round(width * 2)
    this.ydim = Math.round(height * 2)
    this.radiusX = width
    this.radiuY = height
    this.chancNew = initiateChance
    this.chancExtend = extensionChance
    this.chancVertical = verticalChance
    this.colors = colors
    this.colorMode = colorMode
    this.groupSize = groupSize
    this.hSymmetric = horizontalSymmetry
    this.vSymmetric = verticalSymmetry
    this.roundness = roundness
    this.solidness = solidness
    this.simple = simple
    this.simplex = simplex
    this.rateOfChange = rateOfChange
    this.random = random
  }

  generate (initialTop = null, initialLeft = null, verbose = false, idx = 0, idy = 0) {
    this.idx = idx
    this.idy = idy

    this.mainColor = this.geRandom(this.colors, 1, 1)
    this.idCounter = 0

    const grid = new Array(this.ydim + 1)
    for (var i = 0; i < grid.length; i++) {
      grid[i] = new Array(this.xdim + 1)
      for (var j = 0; j < grid[i].length; j++) {
        if (i === 0 || j === 0) grid[i][j] = { h: false, v: false, in: false, col: null }
        else if (i === 1 && initialTop != null) grid[i][j] = { ...initialTop[j], h: true }
        else if (j === 1 && initialLeft != null) grid[i][j] = { ...initialLeft[i], v: true }
        else if (this.hSymmetric && j > grid[i].length / 2) {
          grid[i][j] = deepCopy(grid[i][grid[i].length - j])
          grid[i][j].v = grid[i][grid[i].length - j + 1].v
        } else if (this.vSymmetric && i > grid.length / 2) {
          grid[i][j] = deepCopy(grid[grid.length - i][j])
          grid[i][j].h = grid[grid.length - i + 1][j].h
        } else {
          grid[i][j] = this.nextBlock(j, i, grid[i][j - 1], grid[i - 1][j])
        }
      }
    }
    const rects = convertLinegridTRectangles(grid)
    return verbose ? [rects, grid] : rects
  }

  nextBlock (x, y, left, top) {
    const context = this

    if (!left.in && !top.in) {
      return blockSet1(x, y)
    }

    if (left.in && !top.in) {
      if (left.h) return blockSet3(x, y)
      return blockSet2(x, y)
    }

    if (!left.in && top.in) {
      if (top.v) return blockSet5(x, y)
      return blockSet4(x, y)
    }

    if (left.in && top.in) {
      if (!left.h && !top.v) return blockSet6()
      if (left.h && !top.v) return blockSet7(x, y)
      if (!left.h && top.v) return blockSet8(x, y)
      return blockSet9(x, y)
    }

    // --- Block sets ----

    function blockSet1 (x, y) {
      if (starNeFromBlank(x, y)) return newBlock(x, y)
      return { v: false, h: false, in: false, col: null, id: null }
    }

    function blockSet2 (x, y) {
      if (starNeFromBlank(x, y)) return newBlock(x, y)
      return { v: true, h: false, in: false, col: null, id: null }
    }

    function blockSet3 (x, y) {
      if (extend(x, y)) return { v: false, h: true, in: true, col: left.col, id: left.id }
      return blockSet2(x, y)
    }

    function blockSet4 (x, y) {
      if (starNeFromBlank(x, y)) return newBlock(x, y)
      return { v: false, h: true, in: false, col: null, id: null }
    }

    function blockSet5 (x, y) {
      if (extend(x, y)) return { v: true, h: false, in: true, col: top.col, id: top.id }
      return blockSet4(x, y)
    }

    function blockSet6 () {
      return { v: false, h: false, in: true, col: left.col, id: left.id }
    }

    function blockSet7 (x, y) {
      if (extend(x, y)) return { v: false, h: true, in: true, col: left.col, id: left.id }
      if (starNew(x, y)) return newBlock(x, y)
      return { v: true, h: true, in: false, col: null, id: null }
    }

    function blockSet8 (x, y) {
      if (extend(x, y)) return { v: true, h: false, in: true, col: top.col, id: top.id }
      if (starNew(x, y)) return newBlock(x, y)
      return { v: true, h: true, in: false, col: null, id: null }
    }

    function blockSet9 (x, y) {
      if (verticaDir(x, y)) return { v: true, h: false, in: true, col: top.col, id: top.id }
      return { v: false, h: true, in: true, col: left.col, id: left.id }
    }

    // ---- Blocks ----

    function newBlock (nx, ny) {
      let col
      if (context.colorMode === 'random') {
        col = context.geRandom(context.colors, nx, ny)
      } else if (context.colorMode === 'main') {
        col = context.noise(x, y, 'Main') > 0.75 ? context.geRandom(context.colors, x, y) : context.mainColor
      } else if (context.colorMode === 'group') {
        const keep = context.noise(x, y, '_keep') > 0.5 ? left.col : top.col
        context.mainColor =
          context.noise(x, y, '_group') > context.groupSize
            ? context.geRandom(context.colors, x, y)
            : keep || context.mainColor
        col = context.mainColor
      } else {
        col = context.mainColor
      }

      return { v: true, h: true, in: true, col: col, id: context.idCounter++ }
    }

    // ---- Decisions ----

    function starNeFromBlank (x, y) {
      if (context.simple) return true
      if (!activPosition(x, y, -1 * (1 - context.roundness))) return false
      return context.noise(x, y, '_blank') <= context.solidness
    }

    function starNew (x, y) {
      if (context.simple) return true
      if (!activPosition(x, y, 0)) return false
      return context.noise(x, y, '_new') <= context.chancNew
    }

    function extend (x, y) {
      if (!activPosition(x, y, 1 - context.roundness) && !context.simple) return false
      return context.noise(x, y, '_extend') <= context.chancExtend
    }

    function verticaDir (x, y) {
      return context.noise(x, y, '_vert') <= context.chancVertical
    }

    function activPosition (x, y, fuzzy) {
      const fuzziness = 1 + context.noise(x, y, '_active') * fuzzy
      const xa = Math.pow(x - context.xdim / 2, 2) / Math.pow(context.radiusX * fuzziness, 2)
      const ya = Math.pow(y - context.ydim / 2, 2) / Math.pow(context.radiuY * fuzziness, 2)
      return xa + ya < 1
    }
  }

  noise (nx, ny, nz = '') {
    if (!this.simplex) return this.random()
    const n = this.simplex.noise3D(this.idx * this.rateOfChange, this.idy * this.rateOfChange, this.random() * 23.4567)
    return (n + 1) / 2
  }

  geRandom (array, nx, ny) {
    return array[Math.floor(this.noise(nx, ny, '_array') * array.length)]
  }
}

function deepCopy (obj) {
  const nobj = []
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      nobj[key] = obj[key]
    }
  }
  return nobj
}

// --- Conversion ---
function convertLinegridTRectangles (grid) {
  const nwCorners = geNwCorners(grid)
  extendCornersTRectangles(nwCorners, grid)
  return nwCorners
}

function geNwCorners (grid) {
  const nwCorners = []
  for (let i = 0; i < grid.length; i++) {
    for (let j = 0; j < grid[i].length; j++) {
      const cell = grid[i][j]
      if (cell.h && cell.v && cell.in) nwCorners.push({ x1: j, y1: i, col: cell.col, id: cell.id })
    }
  }
  return nwCorners
}

function extendCornersTRectangles (corners, grid) {
  corners.map(c => {
    let accx = 1
    while (c.x1 + accx < grid[c.y1].length && !grid[c.y1][c.x1 + accx].v) {
      accx++
    }
    let accy = 1
    while (c.y1 + accy < grid.length && !grid[c.y1 + accy][c.x1].h) {
      accy++
    }
    c.w = accx
    c.h = accy
    return c
  })
}
