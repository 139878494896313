
interface Thing {
  name: string,
  id: number,
  seed: number
}

export default class ThingToken {
  public name = ''
  public symbol = ''

  private COUNTER = 0
  public things: Thing[] = []

  constructor (name, symbol) {
    this.name = name
    this.symbol = symbol
  }

  public createRandomThing (_name?: string): Thing {
    // check if fee is ok
    const seed: number = Math.random() * 1e19

    const newThing: Thing = { name: _name || `Thing #${this.COUNTER}`, id: this.COUNTER, seed }
    this.things.push(newThing)
    // _safeMint() goes here
    this.COUNTER++
    return newThing
  }

  // Getters
  public getThings (): Thing[] {
    return this.things
  }
}
