import art from './art'
import ThingToken from './ThingToken'

const query = Object.fromEntries(location.search.substr(1).split('&').map(param => param.split('=')))

const dom = {
  image: document.querySelector('#image') as HTMLImageElement,
  meta: {
    // name: document.querySelector('.meta > .name > span'),
    id: document.querySelector('.meta > .id span'),
    dna: document.querySelector('.meta > .dna > span'),
    size: document.querySelector('.meta > .size > span'),
    symmetry: document.querySelector('.meta > .symmetry > span'),
    location: document.querySelectorAll('.meta > .location > span')
  },
  lock: {
    dna: document.querySelector('.meta > .dna > .lock'),
    size: document.querySelector('.meta > .size > .lock'),
    symmetry: document.querySelector('.meta > .symmetry > .lock'),
    latitude: document.querySelectorAll('.meta > .location > .lock')[0],
    longitude: document.querySelectorAll('.meta > .location > .lock')[1]
  },
  save: document.querySelector('.meta > .id a') as HTMLAnchorElement
}

const lockedValues = localStorage.getItem('lockedValues')
  ? JSON.parse(localStorage.getItem('lockedValues')) : {
    dna: undefined,
    size: undefined,
    symmetry: undefined,
    latitude: undefined,
    longitude: undefined
  }

function parseThing ({ name, id, seed }) {
  return {
    id,
    name,
    dna: seed % 1e16,
    size: 12 + Math.floor(((seed % 1e5) * 11e-5) ** 2 / 11),
    symmetry: ((seed % 1e5) * 3e-5) ** 10 > 39366 ? 'xy' : ((seed % 1e5) * 3e-5) ** 10 > 19683 ? 'x' : 'none',
    latitude: Math.acos(2 * seed / 1e19 - 1) / Math.PI * 180 - 90,
    longitude: (seed % 36000) / 100 - 180
  }
}

const thingsToken = new ThingToken('Things', 'THING')
let thing = parseThing(thingsToken.createRandomThing())

Object.entries(dom.lock).forEach(([param, btn]) => {
  if (lockedValues[param] !== undefined) btn.classList.add('locked')
  btn.addEventListener('click', () => {
    if (lockedValues[param] === undefined) {
      lockedValues[param] = thing[param]
      btn.classList.add('locked')
    } else {
      lockedValues[param] = undefined
      btn.classList.remove('locked')
    }
    localStorage.setItem('lockedValues', JSON.stringify(lockedValues))
  })
})

function beautifyCoordinates (lat_, lon_) {
  const lat = (lat_ - 9000) / 100
  const lon = (lon_ - 18000) / 100
  const latDeg = Math.floor(Math.abs(lat))
  const lonDeg = Math.floor(Math.abs(lon))
  const latMin = Math.floor((Math.abs(lat) - latDeg) * 60)
  const lonMin = Math.floor((Math.abs(lon) - lonDeg) * 60)
  const latSec = Math.round(((Math.abs(lat) - latDeg) * 60 - latMin) * 60)
  const lonSec = Math.round(((Math.abs(lon) - lonDeg) * 60 - lonMin) * 60)
  return [`${latDeg}°${latMin}'${latSec}"${lat > 0 ? 'N' : 'S'}`, `${lonDeg}°${lonMin}'${lonSec}"${lon > 0 ? 'E' : 'W'}`]
}

generate()
function generate () {
  thing = parseThing(thingsToken.createRandomThing())
  thing.dna = lockedValues.dna === undefined ? (query.dna ? parseInt(query.dna) : thing.dna) : lockedValues.dna
  thing.size = lockedValues.size === undefined ? (query.size ? parseInt(query.size) : thing.size) : lockedValues.size
  thing.symmetry = lockedValues.symmetry === undefined ? (query.symmetry ? parseInt(query.symmetry) : thing.symmetry) : lockedValues.symmetry
  thing.latitude = lockedValues.latitude === undefined ? (query.latitude ? parseInt(query.latitude) : thing.latitude) : lockedValues.latitude
  thing.longitude = lockedValues.longitude === undefined ? (query.longitude ? parseInt(query.longitude) : thing.longitude) : lockedValues.longitude
  // meta.name.textContent = thing.name
  dom.meta.id.textContent = '#' + thing.id
  dom.meta.dna.textContent = thing.dna.toString()
  dom.meta.size.textContent = thing.size.toString()
  dom.meta.symmetry.textContent = thing.symmetry.replace('none', '-').toUpperCase()
  dom.meta.location[0].textContent = beautifyCoordinates(thing.latitude, thing.longitude)[0]
  dom.meta.location[1].textContent = beautifyCoordinates(thing.latitude, thing.longitude)[1]
  dom.save.href = `/?id=${thing.id}&dna=${thing.dna}&size=${thing.size}&symmetry=${thing.symmetry}&latitude=${thing.latitude}&longitude=${thing.longitude}`

  const imgSize = query.img?.split('x').map(v => isNaN(parseInt(v)) ? 1000 : parseInt(v)) || [1000, 1000]
  if (!imgSize.every(s => s === 1000)) dom.save.href += `&img=${imgSize[0]}x${imgSize[1]}`

  dom.image.src = art({
    dna: thing.dna,
    size: thing.size,
    symmetry: thing.symmetry,
    latitude: thing.latitude,
    longitude: thing.longitude,
    width: imgSize[0],
    height: imgSize[1]
  })
}

dom.image.addEventListener('click', generate)
